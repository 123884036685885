
import config from '@/site-config'

export default {
  name: 'MainMenu',
  data() {
    const { routes } = config

    return {
      routes,
    }
  },
  methods: {
    onItemChanged(e, currentItem, lastItem) {
      // if (!currentItem) return
      // const activeLink = document.querySelector('.nuxt-link-exact-active')

      // activeLink && activeLink.classList.remove('nuxt-link-exact-active')
      // currentItem.classList.add('nuxt-link-exact-active')

      this.$emit('itemChange', currentItem)
    },
  },
}
