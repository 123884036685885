
// import LogoText from '@/assets/gd_logo_text--white.svg?inline'
import siteConfig from '@/site-config'

export default {
  name: 'SideBarDesktop',
  // components: {
  //   LogoText,
  // },
  data() {
    return {
      siteConfig,
      currentSection: this.$nuxt.$route.hash || 'home',
      currentBackgroundImage: require(`@/assets/images/${(
        this.$nuxt.$route.hash || '#home'
      ).replace('#', '')}-bg.png`),
    }
  },
  methods: {
    onItemChange(element) {
      this.currentSection = element.dataset.sectionSelector
      this.currentBackgroundImage = require(`@/assets/images/${element.dataset.sectionSelector.replace(
        '#',
        ''
      )}-bg.png`)
    },
  },
}
