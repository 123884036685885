export default {
  phone: [
    { formatted: '(530) 845-0444', url: '5308450444' },
    // { formatted: '(209) 366-4909', url: '2093664909' },
  ],
  email: 'gary@gdfreightllc.com',
  routes: [
    { title: 'Home', path: '/', hash: 'home' },
    { title: 'Services', path: '/', hash: 'services' },
    { title: 'Become A Carrier', path: '/', hash: 'carrier' },
    { title: 'Resources', path: '/', hash: 'resources' },
    { title: 'Contact', path: '/', hash: 'contact' },
  ],
}

export const addMetaTags = (options) =>
  Object.keys(options)
    .map((option) => {
      return [
        {
          hid: `twitter:${option}`,
          name: `twitter:${option}`,
          content: options[option],
        },
        {
          hid: `og:${option}`,
          name: `og:${option}`,
          content: options[option],
        },
      ]
    })
    .flat()
