
import { addMetaTags } from '../site-config'

export default {
  name: 'Default',

  head() {
    return {
      meta: addMetaTags({
        image: require('@/assets/gd_logo_text.svg'),
      }),
    }
  },
}
