import { render, staticRenderFns } from "./default.vue?vue&type=template&id=acc61246&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=acc61246&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acc61246",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SideBarDesktop: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/SideBarDesktop.vue').default})
